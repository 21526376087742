import React from 'react';
import {
  List, Datagrid, TextField, SimpleForm, Create, Edit, DisabledInput, TextInput, ReferenceArrayInput, SelectArrayInput, CardActions, CreateButton, Button,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Visibility } from '@material-ui/icons';

const required = (message = 'Champs requis') => value => (value ? undefined : message);

const MeetingPlaceActions = props => (
  <CardActions>
    <CreateButton {...props} />
    <Button
      color="primary"
      component={Link}
      to="/Doctor"
      label="Afficher les médecins"
    >
      <Visibility />
    </Button>
  </CardActions>
);

export const MeetingPlaceList = props => (
  <List {...props} title="Liste des lieux de consultation" actions={<MeetingPlaceActions />}>
    <Datagrid rowClick="edit">
      <TextField label="Nom" source="name" />
      <TextField label="Ville" source="city" />
      <TextField label="Code Postal" source="postalCode" />
    </Datagrid>
  </List>
);

export const MeetingPlaceCreate = props => (
  <Create {...props} title="Créer un lieux de consultation">
    <SimpleForm>
    <TextInput label="Nom" source="name" validate={required()} />
    <TextInput label="Nom Complet" source="fullName" validate={required()} placeholder="Nom affiché sur les SMS"/>
      <TextInput label="Adresse" source="address" validate={required()} />
      <TextInput label="Ville" source="city" validate={required()} />
      <TextInput label="Code Postal" source="postalCode" validate={required()} />
      <TextInput label="Pays" source="country" validate={required()} />
      <ReferenceArrayInput label="Médecins" source="doctorIds" reference="Doctor" allowEmpty>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

const MeetingPlaceEditTitle = ({ record }) => (
  <span>
Éditer
    {' '}
    {record ? `${record.name}` : ''}
  </span>
);

const parseDoctors = (doctors) => {
  if (doctors) {
    return doctors.map(id => ({ id }));
  }
  return [];
};

const formatDoctors = (doctors) => {
  if (!doctors) {
    return [];
  }
  if (doctors.length > 0 && doctors[0].id) { return doctors.map(doctor => doctor.id); } return doctors;
};

export const MeetingPlaceEdit = props => (
  <Edit {...props} title={<MeetingPlaceEditTitle />}>
    <SimpleForm>
      <DisabledInput label="Id" source="id" />
      <TextInput label="Nom" source="name" validate={required()} />
      <TextInput label="Nom Complet" source="fullName" validate={required()} placeholder="Nom affiché sur les SMS"/>
      <TextInput label="Adresse" source="address" validate={required()} />
      <TextInput label="Ville" source="city" validate={required()} />
      <TextInput label="Code Postal" source="postalCode" validate={required()} />
      <TextInput label="Pays" source="country" validate={required()} />
      <ReferenceArrayInput label="Médecins" parse={parseDoctors} format={formatDoctors} source="doctors" reference="Doctor" allowEmpty>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);
