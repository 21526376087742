import React from 'react';
import PropTypes from 'prop-types';
import {
  required,
  List,
  Datagrid,
  SimpleForm,
  Create,
  TextInput,
  Edit,
  AutocompleteInput,
  TextField,
  TabbedShowLayout,
  Show,
  Tab,
  NumberInput,
  FormDataConsumer,
  BooleanField,
  ReferenceInput,
  ReferenceField,
  FunctionField,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  ArrayField,
} from 'react-admin';
import { LinearProgress } from '@material-ui/core';
import Moment from 'moment';
import withStyles from '@material-ui/styles/withStyles';
import DevicePdfExport from './DevicePdfExport';
import DevicesCalendar from './DevicesCalendar';
import getDeviceListTitle from './getDeviceListTitle';
import {
  TypeChoices, FoamChoices, ImageTypeChoices, DeviceRadioTypes,
} from './constants';
import DoctorQuickCreateButton from './DoctorQuickCreateButton';

const FullNameField = ({ record = {} }) => {
  const patient = record && record.patient ? record.patient : record;

  if (!patient || !patient.firstname) {
    return <div />;
  }
  return <span>{`${patient.lastname} ${patient.firstname}`}</span>;
};
FullNameField.defaultProps = {
  label: 'Patient',
  addLabel: true,
};
FullNameField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

const DeviceListWrapper = (props) => {
  const {
    filterValues, filterValues: { calendar } = {}, ids, data, isLoading, isWorkshopUser,
  } = props;
  if (isLoading) {
    return (<LinearProgress />);
  }
  if (calendar) {
    return (<DevicesCalendar ids={ids} data={data} filterValues={filterValues} height={isWorkshopUser ? '91vh' : '84vh'} />);
  }
  return (
    <Datagrid {...props} rowClick="show">
      <TextField label="Type d'appareil" source="type" />
      <ReferenceField label="Patient" source="patient.id" reference="Patient" linkType="show" sortable={false}>
        <FunctionField render={record => `${record.lastname} ${record.firstname}`} />
      </ReferenceField>
    </Datagrid>
  );
};

export const DeviceList = withStyles({ container: { '& >div': { overflow: 'scroll' } } })(({ classes, permissions, ...props }) => (
  <List
    {...props}
    title={getDeviceListTitle()}
    bulkActionButtons={false}
    perPage={150}
    actions={permissions === 'Workshop' ? null : undefined}
    pagination={null}
    sort={{ field: 'id', order: 'DESC' }}
    // TODO There is a problem with this system at login time with workshop users
    // to fix this a solution can be to add a dashboard page with nothing to avoid this error page
    // for now if we click on a menu link it fix the thing
    filterDefaultValues={permissions === 'Workshop' ? { calendar: 'fitting' } : undefined}
    className={classes.container}
  >
    <DeviceListWrapper isWorkshopUser={permissions === 'Workshop'} />
  </List>
));

const getCodesByType = (type) => {
  const typeChoice = TypeChoices.find(element => element.id === type);
  if (!typeChoice) {
    return [];
  }
  return typeChoice.codes.map(codeId => ({ code: codeId }));
};

const getImageType = (type) => {
  const typeData = TypeChoices.find(element => element.id === type);
  return typeData && typeData.imageType;
};

export const DeviceCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <ReferenceInput
        label="Patient"
        reference="Patient"
        source="patientId"
        validate={required()}
        perPage={10}
        helperText="Nom Prénom Date de naissance (08-06-2001)"
      >
        <AutocompleteInput
          optionText={patient => `${patient.lastname.toUpperCase()} ${patient.firstname
            .charAt(0)
            .toUpperCase() + patient.firstname.slice(1)} ${
            patient.birthdate
              ? ` ${new Moment(patient.birthdate).format('L')}`
              : ''
          }`
            }
          inputValueMatcher={() => null}
        />

      </ReferenceInput>
      <ReferenceInput
        label="Opérateur"
        reference="User"
        source="operator.id"
        filter={{ type: 'Operator' }}
        allowEmpty
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <ReferenceInput
          label="Docteur"
          reference="Doctor"
          source="doctor.id"
          allowEmpty
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <DoctorQuickCreateButton />
      </div>

      <div style={{ display: 'flex' }}>
        <div>
          <AutocompleteInput
            source="type"
            label="Type d'appareil"
            choices={TypeChoices}
          />
        </div>
        <div style={{ marginLeft: '20px' }}>
          <TextInput
            source="type"
            label="Appareil personnalisé"
          />
        </div>
      </div>
      <TextInput source="side" label="Côté" />
      <ReferenceInput
        label="Couleur 1"
        reference="DeviceColor"
        source="firstColor"
        allowEmpty
        perPage={100}
      >
        <AutocompleteInput optionText="name" optionValue="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Couleur 2"
        reference="DeviceColor"
        source="secondColor"
        allowEmpty
        perPage={100}
      >
        <AutocompleteInput optionText="name" optionValue="name" />
      </ReferenceInput>
      <SelectInput
        source="foamColor"
        label="Couleur Mousse"
        choices={FoamChoices}
      />
      <NumberInput
        source="frontThickness"
        label="Épaisseur avant (mm)"
      />
      <NumberInput
        source="backThickness"
        label="Épaisseur arrière (mm)"
      />
      <SelectInput source="radioType" choices={DeviceRadioTypes} defaultValue="DropboxRx" />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const { type } = formData;
          return (
            <ArrayInput source="codes" defaultValue={getCodesByType(type)} {...rest}>
              <SimpleFormIterator>
                <ReferenceInput label="Code" source="code" reference="DeviceCode">
                  <AutocompleteInput optionText="alphanumericCode" />
                </ReferenceInput>
                <TextInput
                  source="quantity"
                  label="Quantité/Taille"
                />
              </SimpleFormIterator>
            </ArrayInput>
          );
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) => {
          const { type } = formData;
          return (
            <SelectInput
              source="imageType"
              label="Image"
              choices={ImageTypeChoices}
              defaultValue={getImageType(type)}
            />
          );
        }}
      </FormDataConsumer>
      <TextInput multiline source="correction" label="Correction" />
    </SimpleForm>
  </Create>
);

const DeviceEditTitle = ({ record }) => (
  <span>
Éditer
    {' '}
    {record ? `${record.type.toUpperCase()}` : ''}
  </span>
);

export const DeviceEdit = props => (
  <Edit {...props} title={<DeviceEditTitle />}>
    <SimpleForm redirect="show">
      <ReferenceInput
        label="Opérateur"
        reference="User"
        source="operator.id"
        filter={{ type: 'Operator' }}
        allowEmpty
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Docteur"
        reference="Doctor"
        source="doctor.id"
        allowEmpty
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <div style={{ display: 'flex' }}>
        <div>
          <AutocompleteInput
            source="type"
            label="Type d'appareil"
            choices={TypeChoices}
          />
        </div>
        <div style={{ marginLeft: '20px' }}>
          <TextInput
            source="type"
            label="Appareil personnalisé"
          />
        </div>
      </div>
      <TextInput source="side" label="Côté" />
      <ReferenceInput
        label="Couleur 1"
        reference="DeviceColor"
        source="firstColor"
        allowEmpty
        perPage={100}
      >
        <AutocompleteInput optionText="name" optionValue="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Couleur 2"
        reference="DeviceColor"
        source="secondColor"
        allowEmpty
        perPage={100}
      >
        <AutocompleteInput optionText="name" optionValue="name" />
      </ReferenceInput>
      <SelectInput
        source="foamColor"
        label="Couleur Mousse"
        choices={FoamChoices}
      />
      <NumberInput
        source="frontThickness"
        label="Épaisseur avant (mm)"
      />
      <NumberInput
        source="backThickness"
        label="Épaisseur arrière (mm)"
      />
      <SelectInput source="radioType" choices={DeviceRadioTypes} defaultValue="DropboxRx" />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const { type } = formData;
          return (
            <ArrayInput source="codes" defaultValue={getCodesByType(type)} {...rest}>
              <SimpleFormIterator>
                <ReferenceInput label="Code" source="code" reference="DeviceCode">
                  <AutocompleteInput optionText="alphanumericCode" />
                </ReferenceInput>
                <TextInput
                  source="quantity"
                  label="Quantité/Taille"
                />
              </SimpleFormIterator>
            </ArrayInput>
          );
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) => {
          const { type } = formData;
          return (
            <SelectInput
              source="imageType"
              label="Image"
              choices={ImageTypeChoices}
              defaultValue={getImageType(type)}
            />
          );
        }}
      </FormDataConsumer>
      <TextInput multiline source="correction" label="Correction" style={{ width: '500px', maxWidth: '100%' }} />
    </SimpleForm>
  </Edit>
);

const DeviceShowTitle = ({ record }) => (
  <span>
    {record
      ? `${record
        .type
        .toUpperCase()}`
      : ''}
  </span>
);

export const DeviceShow = props => (
  <Show {...props} title={<DeviceShowTitle />}>
    <TabbedShowLayout>
      <Tab label="informations">
        <ReferenceField
          label="Patient"
          record={{
            firstname: 'test',
          }}
          source="patient.id"
          reference="Patient"
          linkType="show"
        >
          <FullNameField source="firstname" />
        </ReferenceField>
        <ReferenceField
          label="Opérateur"
          source="operator.id"
          reference="User"
          linkType="show"
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Docteur"
          source="doctor.id"
          reference="Doctor"
          linkType="show"
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField label="Type d'appareil" source="type" />
        <TextField source="side" label="Côté" />
        <TextField label="Couleur 1" source="firstColor" />
        <TextField label="Couleur 2" source="secondColor" />
        <TextField label="Couleur Mousse" source="foamColor" />
        <TextField label="Épaisseur avant (mm)" source="frontThickness" />
        <TextField label="Épaisseur arrière (mm)" source="backThickness" />
        <BooleanField label="Direct (sans essayage)" source="isWithoutTryOn" />
        <ArrayField source="codes">
          <Datagrid>
            <ReferenceField label="Code" source="code" reference="DeviceCode" linkType={false}>
              <TextField source="alphanumericCode" />
            </ReferenceField>
            <TextField label="Quantité" source="quantity" />
          </Datagrid>
        </ArrayField>
        <TextField source="imageType" label="Image" />
        <TextField component="pre" label="Correction" source="correction" />
      </Tab>
      <Tab label="Fiche">
        <DevicePdfExport />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
