import gql from 'graphql-tag';
import _ from 'lodash';
import buildFieldList from './buildFieldList';

function lowerFirstLetter(s) {
  return s.charAt(0).toLowerCase() + s.slice(1);
}

const UPDATE_REMOVE_FIELDS = {
  MeetingList: ['meetings', 'place', 'doctors'],
  Patient: ['meetings', 'devices', 'meetingIds', 'deviceIds'],
  Meeting: ['meetingList', 'patient', 'device.type', 'device.createdAt'],
  UserUnavailability: ['createdAt', 'updatedAt', 'operator'],
  Device: ['patient', 'meetings', 'operator.name', 'doctor.name', 'isWithoutTryOn', 'createdAt'],
};

function filterData(ressourceName, data) {
  const rtn = { ...data };
  if (UPDATE_REMOVE_FIELDS[ressourceName]) {
    UPDATE_REMOVE_FIELDS[ressourceName].forEach((key) => {
      _.unset(rtn, key);
    });
  }
  return rtn;
}

export default introspectionResults => (raFetchType, resourceName, params) => {
  console.log(raFetchType);
  console.log(resourceName);
  console.log(params);
  let data = { ...params.data };
  if (params) {
    if (raFetchType === 'UPDATE') {
      data = filterData(resourceName, params.data);
    }
    data = JSON.stringify(data);
    if (raFetchType === 'GET_LIST') {
      data = JSON.stringify(params);
    }
    if (raFetchType === 'GET_MANY') {
      data = JSON.stringify({ pagination: { page: 1, perPage: params.ids.length }, sort: { field: 'id', order: 'DESC' }, filter: params });
    }
    if (data) {
      data = data.replace(/"([^(")"]+)":/g, '$1:');
      if (data.length > 2) {
        data = data.substr(0, data.length - 1);
        data = data.substr(1, data.length - 1);
      }
    }
  }

  let getListPlural = `${lowerFirstLetter(resourceName)}s`;
  if (resourceName === 'UserUnavailability') {
    getListPlural = 'userUnavailabilities';
  }
  console.log(getListPlural);

  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`query ${getListPlural} {
                    ${getListPlural}(${data}) {
                        ${buildFieldList(introspectionResults, resourceName, raFetchType)}
                    }
                }`,
        variables: params, // params = { id: ... }
        parseResponse: response => response.data[`${getListPlural}`],
      };
    case 'GET_ONE':
      return {
        query: gql`query ${lowerFirstLetter(resourceName)}($id: ID!) {
                    ${lowerFirstLetter(resourceName)}(id: $id) {
                        ${buildFieldList(introspectionResults, resourceName, raFetchType)}
                    }
                }`,
        variables: params, // params = { id: ... }
        parseResponse: response => ({ data: response.data[lowerFirstLetter(resourceName)] }),
      };
    case 'CREATE':
      return {
        query: gql`mutation create${resourceName} {
          create${resourceName}(${data}) {
              ${buildFieldList(introspectionResults, resourceName, raFetchType)}
          }
      }`,
        variables: params, // params = { id: ... }
        parseResponse: response => ({ data: response.data[`create${resourceName}`] }),
      };
    case 'UPDATE':
      if (resourceName === 'generateWeek') {
        return {
          query: gql`mutation ${resourceName} {
            ${resourceName}(startAt: "${params.startAt}") {
                errors
            }
        }`,
          variables: params, // params = { id: ... }
          parseResponse: response => ({ data: response.data[`update${resourceName}`] }),
        };
      }
      return {
        query: gql`mutation update${resourceName} {
          update${resourceName}(${data}) {
              ${buildFieldList(introspectionResults, resourceName, raFetchType)}
          }
      }`,
        variables: params, // params = { id: ... }
        parseResponse: response => ({ data: response.data[`update${resourceName}`] }),
      };
    case 'DELETE':
      return {
        query: gql`mutation delete${resourceName}($id: ID!) {
          delete${resourceName}(id: $id) {
              ${buildFieldList(introspectionResults, resourceName, raFetchType)}
          }
      }`,
        variables: params, // params = { id: ... }
        parseResponse: response => ({ data: response.data[`delete${resourceName}`] }),
      };
    case 'GET_MANY':
      return {
        query: gql`query ${getListPlural} {
                    ${getListPlural}(${data}) {
                        ${buildFieldList(introspectionResults, resourceName, raFetchType)}
                    }
                }`,
        variables: params, // params = { id: ... }
        parseResponse: (response) => { const rtn = response.data[`${getListPlural}`]; delete rtn.total; console.log(rtn); return rtn; },
      };
    default:
      console.log(`Sorry, we are out of ${raFetchType}.`);
      return null;
  }
};
